import React from "react";
import { Container, Row, Col } from "react-bootstrap";
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faFacebookSquare, faInstagram } from "@fortawesome/free-brands-svg-icons";

import '../Components/landing.css';
import '../theme/index.css';
import { Header } from '../Components/components-index';

//import prayerFlag from '../assets/2024/landing/prayer-flags-bkg.webp';

export const Landing = () => {
    return (
        <Container fluid className="px-0">
            <Header/>
        </Container>
    )
};