import React from "react";
import {Row, Col } from "react-bootstrap";

import '../../Components/landing.css';
import '../../theme/index.css';

import logo from '../../assets/CASPER KARMA YOGA FEST_LOGO.webp';
import saveTheDate from '../../assets/2024/landing/SaveTheDate.webp';

export const Header = () => {
    return (
        <div id="landing" className="pb-0 landing-bkg-container">
            <Row className="justify-content-between py-0 mx-0">
                <Col md="auto" className="p-0">
                    <img src={logo} alt="" className="landing-logo pt-1 ps-3" />
                </Col>
            </Row>
            <Row className="justify-content-center mt-4 p-0 mx-0">
                <Col lg={8} xs={12}>
                    <img src={saveTheDate} className="save-the-date" alt="Save the Date" />
                </Col>
            </Row>
            <Row className="justify-content-center align-items-center p-4 mx-0">
                <Col md={4} sm={6} xs={8} className="p-2">
                    <h1 className="text-white text-center">SATURDAY<br /> <strong>JULY 19TH</strong></h1>
                </Col>
            </Row>
            <Row className="mx-0">
                <Col xs={12} className="justify-content-center pb-5 px-0">
                    <h4 className="white-text text-center p-1 px-0 mx-4">
                        Yoga, Music, Kids Corner, Food Trucks, & More!
                    </h4>
                </Col>
            </Row>
            <Row className="text-center pb-5 mx-0">
                <Col>
                    <h2 className="white-text">Washington Park</h2>
                    <h3 className="white-text">Casper, WY</h3>
                </Col>
            </Row>
            <Row className="justify-content-center pt-3 mx-0">
                    <Col md={12} className="text-center white-text">
                        <p><small>Copyright © 2025 Casper Karma Yoga Festival, all rights reserved.</small></p>
                    </Col>
                </Row>
        </div>
    )
};